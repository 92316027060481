module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"./src/images/favicons-black/android-chrome-512x512.png","icons":[{"src":"./src/images/favicons-black/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"./src/images/favicons-black/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"./src/images/favicons-black/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"./src/images/favicons-black/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"name":"PrescribeLife","short_name":"PrescribeLife","Description":"Tailoring health solutions for startup founders. A team of\n        world-class doctors, physicians, and coaches ensures a unique\n        blend of care and knowledge. We adapt them to your individual\n        circumstances and business goals.","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1bfa694a1214730d44ed4d55c8c58cb9"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
